import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./components/home";
import Board from "./components/board";
import Management from "./components/management";
import Contact from "./components/contact";
import Imprint from "./components/imprint"
import Topmenu from "./components/topmenu";

function App() {
    return (
        <BrowserRouter>
            <div className="page-wrapper">
                <div className="jem_header_home" id="youtube-video">
                    <Topmenu/>
                </div>
                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/board' element={<Board/>}/>
                    <Route path='/management' element={<Management/>}/>
                    <Route path='/contact' element={<Contact/>}/>
                    <Route path='/imprint' element={<Imprint/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;

