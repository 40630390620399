import React from "react";
import FooterV1 from "../library/FooterV1";
import BoardTeam from "../library/BoardTeam";

const Board = () => {
    const sendNotification = async () => {
        const endpoint = "https://7edfac6a-11e2-417a-b364-1f361d7c45a5.pushnotifications.pusher.com/publish_api/v1/instances/7edfac6a-11e2-417a-b364-1f361d7c45a5/publishes";
        const authToken = "D91F0D14E10DA435F27E48E79A314AC1B368212B78F4A57C26A57CB080653706";

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                interests: ["hello"],
                apns: {
                    aps: {
                        alert: {
                            title: "Hello",
                            body: "Hello, world!"
                        }
                    }
                }
            })
        };

        try {
            const response = await fetch(endpoint, requestOptions);
            if (response.ok) {
                console.log("Notification sent successfully!");
            } else {
                console.error("Failed to send notification:", response.statusText);
            }
        } catch (error) {
            console.error("Error sending notification:", error);
        }
    };

    return (
        <>
            <BoardTeam teamClass="pb-200" partial={false} />
            <button onClick={sendNotification}>SN</button>
            <FooterV1/>
        </>
    );
}

export default Board;
