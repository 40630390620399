import React from 'react';
import SocialShare from './SocialShare';

const SingleTeamV4 = ({ team }) => {
    const { thumb, name, designation, linkedin, email } = team

    return (
        <>
            <div className="team-member-four" style={{height: '490px'}}>
                <div className="image" style={{padding: '10px'}}>
                    <img src={`/assets/images/${thumb}`} alt="Membre de l'équipe"
                         style={{width: '200px', height: '300px', objectFit: 'cover'}}/>
                    <div className="social-style-two">
                        <SocialShare mylink={linkedin}/>
                    </div>
                </div>
                <div className="member-designation" style={{padding: '10px'}}>
                    <h5>{name}</h5>
                    <h7>{designation}</h7>
                    <br/>
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
            </div>
        </>
    );
};

export default SingleTeamV4;