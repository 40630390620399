import React from "react";

const Imprint = () => {
    return (
        <>
            <p>hi</p>
        </>
    )
}

export default Imprint;
