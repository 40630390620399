import React, { Component } from "react";
import {Link} from "react-router-dom";
import FooterV1 from "../library/FooterV1";
import ContactForm from '../library/ContactForm';


class Contact extends Component {


    render() {
        return (
            <>
                <section className="contact-page py-120 rpy-100">
                    <div className="container">
                        <div className="contact-info-area mb-80">
                            <div className="contact-info-item">
                                <i className="far fa-map"></i>
                                <p>Samstagernstrasse 41
                                    CH-8832 Wollerau</p>
                            </div>
                            <div className="contact-info-item">
                                <i className="far fa-envelope"></i>
                                <p>
                                    <a href="mailto:info@swiss-pp.com">info@swiss-pp.com</a> <br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <FooterV1/>
            </>
        );
    }
}

export default Contact;
