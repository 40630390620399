import React from "react";
import {Link} from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

const Topmenu = () =>{
    return(
        <>
        <header className="main-header header-one">
            <div className="header-upper bg-white">
                <div className="container-fluid clearfix">
                    <div className="header-inner d-flex align-items-center">
                        <div className="logo-outer bg-white py-0 px-25">
                            <div className="logo">
                                <Link to="/">&nbsp;<img src="/assets/images/web_logo.png" alt="Logo"
                                                        title="Logo"/></Link>
                            </div>
                        </div>

                        <div className="nav-outer clearfix d-flex align-items-center">
                            <nav className="main-menu navbar-expand-lg">
                                <div className="navbar-header">
                                    <div className="mobile-logo bg-white p-15">
                                        <Link to="index.html">
                                            <img src="/assets/images/web_logo.png" alt="Logo" title="Logo" style={{ maxWidth: '100%', maxHeight: '100%' }}/></Link>
                                    </div>
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                        <span className="icon-bar">&nbsp;</span>
                                        <span className="icon-bar">&nbsp;</span>
                                        <span className="icon-bar">&nbsp;</span>
                                    </button>
                                </div>
                                <div className="navbar-collapse collapse clearfix">
                                    <ul className="navigation clearfix">
                                        <li className="main-menu">&nbsp;<Link to="/board" style={{
                                            fontWeight: "bold",
                                            fontSize: "1.0rem"
                                        }}>Board</Link>
                                        </li>
                                        <li className="main-menu">&nbsp;<Link to="/management" style={{
                                            fontWeight: "bold",
                                            fontSize: "1.0rem"
                                        }}>Management</Link>
                                        </li>
                                        <li className="dropdown">&nbsp;<Link to="/contact" style={{
                                            fontWeight: "bold",
                                            fontSize: "1.0rem"
                                        }}>Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
);
}

export default Topmenu;