import React from "react";
import FooterV1 from "../library/FooterV1";
import ManagmentTeam from "../library/ManagmentTeam";

const Management = () => {
    return (
        <>
            <ManagmentTeam teamClass="pb-200" partial={false} />
            <FooterV1/>
        </>
    );
}

export default Management;