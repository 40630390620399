import React  from "react";
import { Link } from 'react-router-dom';
import SingleGalleryV2 from "../library/SingleGalleryV2";
import FooterV1 from "../library/FooterV1";
import GalleryV2Data from "../library/GalleryV2Data.json";

const Home = () => {

    return (
        <>
            <section className="hero-section overlay bgs-cover pt-200 pb-150"
                     style={{backgroundImage: "url(assets/images/field.jpg)"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-9">
                            <div className="hero-content text-center text-white">
                                    <span className="sub-title d-block">Swiss Pro Promotion provides strategic consultancy,
                                        management support, and strategic investments to high potential football clubs worldwide
                                        with the aim to identify and define club and location specific economic opportunities
                                        that allow for sustained monetization, benefitting the clubs, their community,
                                        its stakeholders and shareholders.</span>
                                <h1 className="mt-20">Swiss Pro Promotion</h1>
                                <div className="hero-btn mt-35">
                                    <Link to="/contact" className="theme-btn">Contact us</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="gallery-section-two overlay pt-115 rpt-95 pb-85 rpb-65 px-60 rpx-0">
                <div className="container-fluid">
                    <div className="section-title text-center mb-55">
                        <span className="sub-title">Our Services</span>
                    </div>
                    <div className="row text-white">
                        <>
                            {GalleryV2Data.map(gallery =>
                                <div className="col-xl-4 col-md-6" key={gallery.id}>
                                    <SingleGalleryV2 gallery={gallery}/>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </section>
            <FooterV1/>

        </>
    );
}

    export default Home;