import React from 'react';
import { Link } from 'react-router-dom';


const FooterV1 = () => {

    const handleEmail = (event) => {
        event.preventDefault()
        event.target.reset()
    }

    return (
        <>
            <footer className="main-footer footer-one text-white">
                <div className="footer-widget-area bgs-cover pt-100 pb-50" style={{ backgroundImage: "url(/assets/images/footer/footer-bg-dots.png)" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-sm-6">
                                <div className="footer-widget about-widget">
                                    <div className="footer-logo mb-35">
                                        <Link to="/#"><img src="/assets/images/web_logo.png" alt="Logo" /></Link>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-6">
                                <div className="footer-widget link-widget ml-20 rml-0">
                                    <h4 className="footer-title">Links</h4>
                                    <ul className="list-style-two">
                                        <li><Link to="/imprint">Imprint</Link></li>
                                        <li><Link to="/policy">Privacy policy</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-widget contact-widget mr-30 rmr-0">
                                    <h4 className="footer-title">Contact</h4>
                                    <ul className="list-style-two">
                                        <li><i className="fas fa-map-marker-alt"></i>Swiss Pro Promotion GmbH<br/>Samstagernstrasse 41<br/>CH-8832 Wollerau
                                        </li>

                                        <li><i className="fas fa-envelope"></i> <Link
                                            to="mailto:info@swiss-pp.com">info@swiss-pp.com</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area bg-blue">
                    <div className="container">
                        <div className="copyright-inner pt-15">
                            <div className="social-style-one mb-10">
                            </div>
                            <p>&copy; {(new Date().getFullYear())} Swiss Pro Promotion GmbH. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterV1;