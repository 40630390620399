import React from 'react';
import { Link } from 'react-router-dom';

const SingleGalleryV2 = ({ gallery }) => {
    const { thumb, title, id } = gallery;
    return (
        <>
            <div className="gallery-item style-two  delay-0-2s">
                <img src={`../assets/images/${thumb}`} alt="Gallery" />
                <div className="gallery-content">
                    <div className="gallery-content-inner">
                        <h5>{title}</h5>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SingleGalleryV2;