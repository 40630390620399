import React from 'react';
import { Link } from 'react-router-dom';
const SocialShare = ({mylink}) => {
    return (
        <>
            <ul className='social-share' >
                <li><Link to={mylink} target='_blank' ><i className="fab fa-linkedin" style={{width: "25px", height: "25px" }}></i></Link></li>
            </ul>
        </>
    );
};

export default SocialShare;